
import { Options, Vue } from 'vue-class-component';
import GrayHeader from '@/components/grayHeader.vue';
import { useStore, Store } from 'vuex';
import { searchToObject, pushWindow } from '@/utils/commom';
import { channelMap } from '@/utils/channelMap';

@Options({
    components: {
        GrayHeader,
    },
})
export default class channelDetail extends Vue {
    private store: Store<any> = useStore();
    private orderMap = channelMap;
    get userStatus() {
        return this.store.state.userStatus;
    }
    get raceInfo() {
        return this.store.state.raceInfo;
    }
    get teamInfo() {
        return this.store.state.teamInfo;
    }
    get curChannelInfo(): any {
        const { id } = searchToObject(window.location.search);
        return this.store.state.channelInfo.find((item: any) => item.Id === id);
    }
    get contentStatic(): String {
        return this.curChannelInfo?.Info;
    }
    get hasLogin(): Boolean {
        return this.store.state.loginStatus.haslogin;
    }
    toProjectData() {
        pushWindow('https://console.cloud.tencent.com/taop/project-data');
    }
}
