import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48ff57d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "track-details" }
const _hoisted_2 = { class: "track-details-wrap" }
const _hoisted_3 = {
  class: "t-container",
  style: {"margin-bottom":"150px"}
}
const _hoisted_4 = { class: "track-details-top" }
const _hoisted_5 = { class: "track-details-top__left" }
const _hoisted_6 = { class: "track-details-top__right" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.curChannelInfo?.Title), 1),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.hasLogin)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "track-details-top__btn",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toProjectData && _ctx.toProjectData(...args)))
                }, "查看数据"))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", {
          class: "content-detail",
          innerHTML: _ctx.contentStatic
        }, null, 8, _hoisted_7)
      ])
    ])
  ]))
}